import React from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import './App.css';

function App() {
  const url = 'https://blog.cuprumz.com';

  return (
    <Container>
      <Row>
        <Col>
          <Card className="text-center full">
            <Card.Header>TODO</Card.Header>
            <Card.Body>
              <Card.Title>Cuprumz's Personal Website</Card.Title>
              <Card.Text>
                to be continued.
              </Card.Text>
              <Button variant="primary" size="lg" block href={url}>Go Blog</Button>
            </Card.Body>
            <Card.Footer className="text-muted">WHERE IS THE ROAD.</Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
